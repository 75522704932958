<template>
  <div>
    <v-toolbar flat>
      <v-icon left>mdi-cogs</v-icon>
      <v-toolbar-title>AUTOMATIZACIONES</v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>

      <v-spacer></v-spacer>
      <v-btn @click="abrirWorkflowDialog(null)" color="blue" small dark
        ><v-icon>mdi-plus</v-icon>Crear
      </v-btn>
    </v-toolbar>
    <v-toolbar flat>
      <v-col>
        <v-text-field
          clearable
          label="Nombres"
          v-model="busWorkflow"
        ></v-text-field>
      </v-col>
    </v-toolbar>

    <v-data-table
      :headers="headerWorkflow"
      :items-per-page="itemsPerPage"
      :items="workflows"
      class="elevation-1"
      :search="busWorkflow"
      small
      hide-default-footer
    >
      <template v-slot:item.wf_id="{ item }">
        <v-btn
          text
          title="Abrir workflow"
          @click="abrirWorkflowDialog(item.wf_id)"
        >
          {{ item.wf_id }}
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table>

    <!-- DIALOGO WORKFLOW-->
    <v-dialog v-model="dialogoWorkflow" width="800px">
      <v-card class="ma-0 pa-0">
        
        <v-toolbar dark color="blue" height="40" flat>
          <v-icon left>mdi-cogs</v-icon>
          Workflow #{{ datosWorkflow.wf_id }}
          <v-spacer></v-spacer>
          <v-btn small text @click="dialogoWorkflow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>{{ datosWorkflow.wf_id }}</v-toolbar-title>
          <v-divider class="px-1 mx-4" vertical></v-divider>
          <v-btn color="green" dark small @click="guardarWorkflow"
            ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
          >
        </v-toolbar>
        <v-card-text class="px-1">
        <v-form ref="formWorkflow">
        <v-row class="mt-1 mx-2">
          <v-col cols="12" class="my-0 py-0">
            <v-text-field
              :rules="requiredRule"
              class="my-0 py-0"
              label="Nombre"
              v-model="datosWorkflow.wf_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-text-field
             :rules="requiredRule"
              class="my-0 py-0"
              label="Estado"
              v-model="datosWorkflow.status_id"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-text-field
              :rules="requiredRule"
              class="my-0 py-0"
              label="Modulo"
              v-model="datosWorkflow.wf_module_id"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-text-field
             :rules="requiredRule"
              class="my-0 py-0"
              label="Triger"
              v-model="datosWorkflow.wf_triger"
            ></v-text-field>
          </v-col>
        </v-row>
        </v-form>
        <v-row>
          <v-col>
            <v-toolbar flat>
              <v-toolbar-title>Condiciones</v-toolbar-title>
              <v-col>
                <v-btn title="Agregar condición" color="green" small dark><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-toolbar>
            <v-data-table
            :headers="headerCond"
            :items="condiciones" 
            hide-default-footer></v-data-table>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <v-toolbar flat>
              <v-toolbar-title>Acciones</v-toolbar-title>
              <v-col>
                <v-btn title="Agregar acción" color="green" small dark><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-toolbar>
            
            <v-data-table
            :headers="headerAcciones"
            :items="acciones" 
            hide-default-footer></v-data-table>
          </v-col>

        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.dgderecha {
  position: absolute;
  left: 0;
  margin: 0;
  min-height: 100%;
}
.absolute {
  position: absolute;
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "Workflows",
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    selected: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    pageCount: 1,

    required: [(v) => !!v || "El campo es requerido"],

    headerWorkflow: [
      { text: "Codigo", align: "start", value: "wf_id" },
      { text: "Nombre", align: "start", value: "wf_name" },
      { text: "Estado", align: "start", value: "status_id" },
    ],
    workflows: [],
    dialogoWorkflow: false,
    datosWorkflow: {
      wf_id: "",
      wf_name: "",
      status_id: "",
      wf_description: "",
      wf_triger: "",
      wf_module_id: "",
      created_stamp: "",
      last_updated_stamp: "",
    },

    datosCond: {},
    datosAction: {},

    condiciones: [],
    headerCond: [ 
      { text: "Codigo", align: "start", value: "wf_cond_id" },
      { text: "Workflow", align: "start", value: "wf_id" },
      { text: "Módulo", align: "start", value: "wf_module_id" },
      { text: "Campo", align: "start", value: "wf_campo" },
      { text: "Valor", align: "start", value: "valor" },
    ],
    acciones: [],
    headerAcciones: [ 
      { text: "Codigo", align: "start", value: "wf_action_id" },
      { text: "Workflow", align: "start", value: "wf_id" },
      { text: "Nombre", align: "start", value: "wf_action_name" },
      { text: "Módulo", align: "start", value: "wf_module_id" },],
    busWorkflow: "",
    operadores: [],
    camposModulo: [],
    modulos: [],
    crearWorkFlow: true
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarListaWorkflows() {
      this.setLoadingTable(true);
      this.setUrl("crm-workflow");
      this.requestApi({
        method: "GET",
        data: {
          view_type: "lista",
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.workflows = res.data._embedded.crm_workflow;
          this.pageCount = res.data.page_count;
          //this.currentPage = res.data.page;
          this.totalItems = res.data.total_items;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirWorkflowDialog(wfId) {
      this.dialogoWorkflow = true;
      if(wfId == null) {
        this.datosWorkflow = {
          wf_id: "",
          wf_name: "",
          status_id: "",
          wf_description: "",
          wf_triger: "",
          wf_module_id: "",
          created_stamp: "",
          last_updated_stamp: "",
        }
      } else {
        this.cargarDatosWorkflow(wfId);
      }
    },

    cargarDatosWorkflow(id) {
      this.setLoadingTable(true);
      this.setUrl("crm-workflow/" + id);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          console.log(res.data.detail);
          this.datosWorkflow = res.data.work_flow;
          this.condiciones = res.data.wf_cond;
          this.acciones = res.data.wf_actions;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarWorkflow() {
      if(!this.$refs.formWorkflow.validate()){
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("crm-workflow");
      let accion = "crearWorkflow"
      if(this.datosWorkflow.wf_id != "") {
        accion = "modificarWorkflow"
      }
      this.requestApi({
        method: "POST",
        data: {
          accion: accion,
          datosWorkflow: this.datosWorkflow,
        },
      })
        .then((res) => {
          //console.log(res.data.detail);
          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
          this.dialogoWorkflow=false;
          this.cargarListaWorkflows()
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    handlePageChange() {
      this.cargarListaWorkflows();
    },

    cargarCampos() {
      this.setLoadingTable(true);
      this.setUrl("crm-workflow");
      this.requestApi({
        method: "GET",
        data: {
          view_type: "camposWorkflow",
        },
      })
        .then((res) => {
          //console.log(res.data.detail);
          this.headerWorkflow = res.data.detail.headerWorkflow;
          this.headerCond = res.data.detail.headerCond;
          this.headerAcciones = res.data.detail.headerAcciones;
          this.campos = res.data.detail.campos;
          this.modulos = res.data.detail.modulos;
          this.operadores = res.data.detail.operadores;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
  },
  mounted() {
    this.cargarListaWorkflows();
    //this.cargarCampos();
    this.setTitleToolbar("AUTOMATIZACIONES");
  },
};
</script>
